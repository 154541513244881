<template>
  <div class="w-full border border-gray-300">
    <h2 class="text-xl p-2">Historique</h2>
    <div class="overflow-y-auto history-h border-gray-300 border-t">
      <HistoryElement v-for="h in history" :key="h.id" :id="h.id" :names="h.names" :father-name="h.fatherName"
                      :mother-name="h.motherName" :arm="h.arm"/>
    </div>
  </div>
</template>

<script>
import HistoryElement from "@/components/HistoryElement.vue";
import HistoryStorage from "@/services/HistoryStorage";

export default {
  name: "History",
  components: {HistoryElement},
  data() {
    return {
      history: []
    };
  },
  created() {
    this.history.push(...HistoryStorage.getHistory());
    HistoryStorage.onAddHistory(history => {
      this.history.push(history);
    });
    HistoryStorage.onRemoveHistory(history => {
      this.history.splice(this.history.indexOf(history), 1);
    });
  }
}
</script>

<style>
.history-h {
  height: calc(100vh - 200px);
}
</style>
