<template>
  <div class="w-full grid grid-cols-3">
    <div class="col-span-2">
      <div class="mb-3">
        <span v-if="error !== false" class="text-red-500">{{ error }}</span>
      </div>
      <div class="grid grid-cols-2 gap-x-2">
        <div id="names-input" class="w-4/5">
          <div class="flex justify-end">
            <font-awesome-icon icon="plus" @click="addName(null)" class="text-green-500 cursor-pointer select-none"/>
          </div>
          <InputName v-for="(item) in names" :index="item.index" :key="item.hash" ref="inputs"
                     @delete="(c) => removeName(c, item)"/>
        </div>
        <div class="w-4/5">
          <Field label="Nom du père" name="father_name" ref="fatherName"/>
          <Field label="Nom de la mère (nom de jeune fille)" name="mother_name" ref="motherName"/>
          <Field label="Date de naissance" name="birthday" type="date" ref="birthday"/>
          <Field label="Tour de poignet" name="arm_size" type="number" ref="arm"/>
          <div class="flex justify-between">
            <label class="inline-flex items-center select-none">
              <input type="checkbox" class="form-checkbox h-4 w-4" checked ref="createPdf">
              <span class="ml-2 text-base text-gray-500">Créer un PDF</span>
            </label>
            <button class="bg-blue-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow outline-none
            hover:bg-blue-700 hover:shadow-lg transition-all duration-150" @click="resolve">
              Calculer
            </button>
          </div>
        </div>
      </div>
      <Modal v-if="modal" :results="results" :pdf="pdf" ref="modal" @close="modal = false"/>
    </div>
    <div class="col-span-1">
      <History/>
    </div>
  </div>
</template>

<script>
import InputName from "@/components/InputName";
import Field from "@/components/Field";
import ApiResource from "@/services/ApiResource";
import {onBeforeUpdate, reactive, ref} from "vue";
import Modal from "@/components/Modal";
import History from "@/components/History.vue";
import HistoryStorage from "@/services/HistoryStorage";

export default {
  name: "Home",
  components: {History, Modal, Field, InputName},
  data() {
    return {
      error: null,
      modal: false,
      results: null,
      pdf: null
    };
  },
  methods: {
    addName() {
      let last = this.names.length > 0 ? this.names[this.names.length - 1].index : 0;
      let hash = Math.round(Date.now() * (Math.random() * 10000));
      const data = {index: last + 1, hash, element: null};
      this.names.push(data);
      return data;
    },
    removeName(component, e) {
      this.names.splice(this.names.indexOf(this.names.find(elm => elm.hash === e.hash)), 1);
      let index = 1;
      this.names.forEach(element => element.index = index++);
    },
    resolveData() {
      let isOnError = false;
      let inputNames = [];
      let fatherName = null;
      let birthday = null;
      this.inputs.sort((a, b) => a.index - b.index).forEach(input => {
        if (typeof input.input.value !== "string" || input.input.value.length === "") isOnError = true;
        else inputNames.push(input.input.value);
      });
      if (typeof this.fatherName.value !== "string" || this.fatherName.value === "") isOnError = true;
      else fatherName = this.fatherName.value;
      let motherName = this.motherName.value;
      if (typeof this.birthday.value !== "string" || this.birthday.value === "") isOnError = true;
      else birthday = this.birthday.value;
      let arm = this.arm.value;
      let pdf = this.createPdf.checked;
      if (isOnError) {
        this.error = "Impossible de traiter les données.";
        return null;
      } else return {names: inputNames, fatherName, motherName, birthday, arm, pdf};
    },
    resolve() {
      this.error = null;
      this.results = null;
      this.pdf = null;
      let data = this.resolveData();
      if (data !== null && typeof data === "object") {
        ApiResource.sendData(data).then(value => {
          HistoryStorage.addHistory(data);
          this.results = value.data.result;
          if (value.data.path !== undefined) this.pdf = value.data.path;
          this.modal = true;
        }).catch(reason => {
          console.log(reason);
          console.log(reason.message);
          console.log(reason.request);
          if (typeof reason.data?.error === "string") this.error = reason.data.error;
          else if (reason.message === "Network Error") this.error = "Impossible de se connecter au serveur. Êtes vous connecté à Internet ?";
          else this.error = "Une erreur est survenue.";
        });
      }
    }
  },
  created() {
    this.addName();
    this.emitter.on("set", data => {
      while (this.names.length) this.removeName(null, this.names[0]);
      for (let i = 0; i < data.names.length; i++) this.addName();
      this.fatherName.value = data.fatherName;
      this.motherName.value = data.motherName;
      this.birthday.value = data.birthday;
      this.arm.value = data.arm;
      // To fix DOM creation
      setTimeout(() => {
        data.names.forEach((n, i) => {
          const input = this.inputs.find(inp => inp.index === i + 1);
          if (input !== undefined) input.input.value = n;
        });
      }, 10);
    });
  },
  setup() {
    const names = ref([]);
    const inputs = ref([]);
    const fatherName = ref(null);
    const motherName = ref(null);
    const birthday = ref(null);
    const arm = ref(null);
    const createPdf = ref(null);
    const modal = ref(false);
    return {names, inputs, fatherName, motherName, birthday, arm, createPdf, modal};
  }
};
</script>
