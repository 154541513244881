<template>
  <div class="flex flex-col items-center mb-6">
    <div class="w-full">
      <label class="block text-gray-500 font-bold mb-1 mb-0 pr-4" :for="name + (index !== null ? index : '')">
        {{ label }}
      </label>
    </div>
    <div class="w-full flex">
      <input :id="name + (index !== null ? index : '')" :type="type" v-model="value" class="bg-gray-200 appearance-none border-2 border-gray-200
      rounded py-2 px-4 text-gray-700 leading-tight hover:bg-gray-300 hover:border-gray-300 focus:outline-none
      focus:bg-white focus:border-green-500 flex-grow">
      <font-awesome-icon v-if="options.trash === true" icon="trash" @click="deleteField"
                         class="place-self-center ml-3 mr-2 cursor-pointer text-red-500 select-none"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Field",
  props: {
    index: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: "input"
    },
    label: {
      type: String,
      default: "Input"
    },
    type: {
      type: String,
      default: "text"
    },
    options: {
      type: Object,
      default: {}
    }
  },
  emits: [
    "delete"
  ],
  data() {
    return {
      value: null
    };
  },
  methods: {
    deleteField() {
      this.$emit("delete", this);
    }
  }
}
</script>
