<template>
  <div class="w-full flex border-b border-gray-300 p-2 cursor-pointer hover:bg-gray-200 ease-in duration-100"
       @click="setData">
    <div class="flex flex-col flex-grow">
      <span>{{ names.join(" ") }}</span>
      <span>{{ fatherName }} / {{ motherName }}</span>
      <span>{{ arm }}</span>
    </div>
    <font-awesome-icon @click.stop.capture="removeItem" icon="trash"
                       class="place-self-center ml-3 mr-2 cursor-pointer text-red-500 select-none"/>
  </div>
</template>

<script>
import HistoryStorage from "@/services/HistoryStorage";

export default {
  name: "HistoryElement",
  props: {
    id: {
      type: Number,
      default: 0
    },
    names: {
      type: Array,
      default: []
    },
    fatherName: {
      type: String,
      default: ""
    },
    motherName: {
      type: String,
      default: ""
    },
    arm: {
      type: Number,
      default: 0
    }
  },
  methods: {
    removeItem() {
      HistoryStorage.removeHistory(this.id);
    },
    setData() {
      this.emitter.emit("set", HistoryStorage.getHistoryById(this.id));
    }
  }
}
</script>
