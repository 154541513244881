export default class HistoryStorage {
    static history = null;
    static addCallbacks = [];
    static removeCallbacks = [];

    static getHistory() {
        if (HistoryStorage.history === null) {
            let history = JSON.parse(localStorage.getItem("history"));
            if (history === null) history = [];
            HistoryStorage.history = history;
        }
        return HistoryStorage.history;
    }

    static getHistoryById(id) {
        return HistoryStorage.getHistory().find(h => h.id === id);
    }

    static addHistory(history) {
        history.hash = HistoryStorage.hashData(history);
        if (HistoryStorage.findHistoryHash(history.hash)) return;
        history.id = Date.now();
        HistoryStorage.history.push(history);
        HistoryStorage.saveHistory();
        HistoryStorage.addCallbacks.forEach(c => {
            c(history);
        });
    }

    static hashData(data) {
        return JSON.stringify(data);
    }

    static findHistoryHash(hash) {
        return HistoryStorage.getHistory().find(h => h.hash === hash);
    }

    static removeHistory(id) {
        const h = HistoryStorage.history.find(h => h.id === id);
        if (h) {
            HistoryStorage.history.splice(HistoryStorage.history.indexOf(h), 1);
            HistoryStorage.saveHistory();
            HistoryStorage.removeCallbacks.forEach(c => {
                c(h);
            });
        }
    }

    static onAddHistory(callback) {
        HistoryStorage.addCallbacks.push(callback);
    }

    static onRemoveHistory(callback) {
        HistoryStorage.removeCallbacks.push(callback);
    }

    static saveHistory() {
        localStorage.setItem("history", JSON.stringify(HistoryStorage.history));
    }
}
