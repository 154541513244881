<template>
  <Field :index="index" label="Prénom" name="input_name-" :options="{trash: true}" ref="input" @delete="onDelete"/>
</template>

<script>
import Field from "@/components/Field";
import {ref} from "vue";

export default {
  name: "InputName",
  components: {Field},
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  emits: ["delete"],
  methods: {
    onDelete(component) {
      this.$emit("delete", component);
    }
  },
  setup() {
    const input = ref(null);

    return {input};
  }
}
</script>
