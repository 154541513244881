<template>
  <div id="default-modal" aria-hidden="true" class="overflow-x-hidden overflow-y-auto fixed md:h-full top-4 left-0
  right-0 md:inset-0 z-50 flex justify-center items-center bg-gray-200 bg-opacity-75">
    <div class="relative w-full max-w-2xl px-4 h-full md:h-auto">
      <div class="bg-white rounded-lg shadow relative dark:bg-gray-700">
        <div class="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-gray-900 text-xl lg:text-2xl font-semibold dark:text-white">
            Résultat
          </h3>
          <button type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal" @click="onClose">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div class="p-6">
          <div v-for="result in results">
            <span>{{ result.position.title }}: {{ result.rock.fullname }}</span>
          </div>
        </div>
        <button v-if="pdf !== null" class="bg-blue-600 text-white font-bold uppercase text-sm ml-6 mb-2 px-4 py-2 rounded shadow outline-none
            hover:bg-blue-700 hover:shadow-lg transition-all duration-150" @click="seePDF">Voir le PDF
        </button>
        <div class="flex space-x-2 justify-end p-6 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button data-modal-toggle="default-modal" type="button"
                  class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                  @click="onClose">
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: [
    "close"
  ],
  props: {
    results: {
      type: Object,
      default: null
    },
    pdf: {
      type: String,
      default: null
    }
  },
  methods: {
    onClose() {
      this.$emit("close", this);
    },
    seePDF() {
      let a = document.createElement("a");
      a.href = this.pdf;
      a.target = "_blank";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
}
</script>
