import axios from "axios";

// Create new axios instance
const http = axios.create({
    baseURL: (process.env.NODE_ENV !== "production" ? "http://localhost:3000" : "") + "/api"
});

// Define Content-Type header for POST request
http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

export default http;
